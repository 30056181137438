<style lang="scss">
  @import './UniversalNavigationSeparator.scss';
</style>

<template>
    <div class="separator" :class="hideCommunityOnCollapse ? 'hide-on-collapse' : ''"></div>
</template>

<script>
export default {
  name: 'UniversalNavigationSeparator',
  props: {
    hideCommunityOnCollapse : {
      type: Boolean,
      default: true
    }
  }
}
</script>