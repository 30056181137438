<style lang="scss">
  @import './UserProfilePicture.scss';
</style>

<template>
    <div v-on:click="$emit('toggleProfileModal')" class="user-profile-picture" :class="hideCommunityOnCollapse ? 'hide-on-collapse' : ''">
        <img :src="profilePictureUrl" :alt="memberName" />
    </div>
</template>

<script>
export default {
  name: 'UserProfilePicture',
  props: {
    hideCommunityOnCollapse : {
      type: Boolean,
      default: true
    },
    memberInfo : {
        type: Object,
        default: null
    }
  },
  computed: {
    profilePictureUrl(){
        if (this.memberInfo === null){
            return 'https://cloud.rtl.it/Member/300/default.jpg';
        }
        
        if (this.memberInfo.images !== null && Object.prototype.hasOwnProperty.call(this.memberInfo.images, '300')){
            return this.memberInfo.images['300'];
        }

        return 'https://cloud.rtl.it/Member/300/default.jpg';
    },
    memberName() {
        if (this.memberInfo === null){
            return 'Utente sconosciuto';
        }

        return `${this.memberInfo.name} ${this.memberInfo.familyName}`;
    }
  }
}
</script>