<style lang="scss">
  @import './UniversalNavigationBar.scss';
</style>

<template>
  <div class="universal-navigation-bar">
    <div v-if="showMenuIcon" class="menu-toggler" v-on:click="toggleMenu">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path d="M 3 7 A 1.0001 1.0001 0 1 0 3 9 L 27 9 A 1.0001 1.0001 0 1 0 27 7 L 3 7 z M 3 14 A 1.0001 1.0001 0 1 0 3 16 L 27 16 A 1.0001 1.0001 0 1 0 27 14 L 3 14 z M 3 21 A 1.0001 1.0001 0 1 0 3 23 L 27 23 A 1.0001 1.0001 0 1 0 27 21 L 3 21 z"/></svg>
    </div>
    <SiteLogo />
    <SiteNavigation :siteList="siteList" :siteSelected="siteSelected" />
    <ChatBotToggler v-on:click="$emit('toggleChatBot')"/>
    <UniversalNavigationSeparator :hideCommunityOnCollapse="hideCommunityOnCollapse" />
    <UserProfilePicture v-on:toggleProfileModal="$emit('toggleProfileModal')" v-if="memberInfo !== null" :hideCommunityOnCollapse="hideCommunityOnCollapse" :memberInfo="memberInfo"/>
    <MyPlayLogin v-on:toggleProfileModal="$emit('toggleProfileModal')" v-if="memberInfo === null" :hideCommunityOnCollapse="hideCommunityOnCollapse" />
  </div>
</template>

<script>
import SiteNavigation from './components/SiteNavigation/SiteNavigation';
import SiteLogo from './components/SiteLogo/SiteLogo';
import ChatBotToggler from './components/ChatBotToggler/ChatBotToggler';
import RTLPlayLogo from './assets/rtl-play-logo.svg';
import MyPlayLogin from './components/MyPlayLogin/MyPlayLogin';
import UserProfilePicture from './components/UserProfilePicture/UserProfilePicture';
import UniversalNavigationSeparator from './components/UniversalNavigationSeparator/UniversalNavigationSeparator';

export default {
  components: { SiteNavigation, SiteLogo, ChatBotToggler, MyPlayLogin, UserProfilePicture, UniversalNavigationSeparator },
  name: 'UniversalNavigationBar',
  data: () => ({
    memberInfo: null,/* {
      "id": 418526,
      "broadcasterLabel": 17,
      "name": "Giorgio",
      "familyName": "Zanoli",
      "email": "giorgio.zanoli@gmail.com",
      "sex": 0,
      "images": {
        "100": "https://cloud.rtl.it/Member/100/r3aj2-hbke7.jpg",
        "300": "https://cloud.rtl.it/Member/300/r3aj2-hbke7.jpg",
        "600": "https://cloud.rtl.it/Member/600/r3aj2-hbke7.jpg",
        "1000": "https://cloud.rtl.it/Member/1000/r3aj2-hbke7.jpg"
      }
    },*/
    showMenuIcon: true,
    hideCommunityOnCollapse: false,
    siteList : [
      { id : 0, url: 'https://www.rtl.it/', name: "RTL 102.5", title: "Vai alla Homepage del sito di RTL 102.5", target: "_blank" },
      { id : 1, url: 'https://www.radiozeta.it/', name: "Radio Zeta", title: "Vai alla Homepage del sito di Radio Zeta", target: "_blank" },
      { id : 2, url: 'https://www.radiofreccia.it/', name: "Radiofreccia", title: "Vai alla Homepage del sito di Radiofreccia", target: "_blank" },
      { id : 3, url: 'https://play.rtl.it/', name: "RTL 102.5 Play", title: "Vai alla Homepage del sito di RTL 102.5 Play", target: "_blank", logo: RTLPlayLogo },
    ],
    siteSelected : -1,
  }),
  methods: {
    toggleMenu(){
      this.$emit('toggleMenu');
    }
  }
}
</script>