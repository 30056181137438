<style lang="scss">
  @import './SiteNavigation.scss';
</style>

<template>
  <nav v-if="siteList.length > 0" class="site-navigation">
    <label v-if="prelabel.length > 0">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3897 2.59212C19.2397 3.98412 21.9997 7.67612 21.9997 12.0001C21.9997 13.6861 21.5777 15.2741 20.8377 16.6701C21.6377 17.8381 21.5217 19.4461 20.4837 20.4841C19.6037 21.3641 18.3117 21.5821 17.2277 21.1401C17.0277 21.0641 16.8337 20.9681 16.6497 20.8481C15.2597 21.5821 13.6777 22.0001 11.9997 22.0001C10.7837 22.0001 9.61769 21.7821 8.53969 21.3821C4.68969 19.9901 1.92969 16.2981 1.92969 11.9741C1.92969 10.2881 2.35169 8.70012 3.09169 7.30412C2.29169 6.13612 2.40769 4.52812 3.44569 3.49012C4.32569 2.61012 5.61769 2.39212 6.70169 2.83412C6.90169 2.91012 7.09569 3.00612 7.27969 3.12612C8.66969 2.39212 10.2517 1.97412 11.9297 1.97412C13.1457 1.97412 14.3117 2.19212 15.3897 2.59212ZM4.79969 8.51612C4.28769 9.57012 3.99969 10.7521 3.99969 12.0001C3.99969 15.4361 6.17969 18.3741 9.22769 19.5041C10.0737 19.8101 10.9837 19.9761 11.9317 19.9761C13.1797 19.9761 14.3617 19.6881 15.4157 19.1761C15.1217 18.1601 15.3757 17.0181 16.1757 16.2181C16.9757 15.4161 18.1177 15.1641 19.1337 15.4581C19.6457 14.4041 19.9337 13.2221 19.9337 11.9741C19.9337 8.53812 17.7537 5.60012 14.7057 4.47012C13.8597 4.16412 12.9497 3.99812 12.0017 3.99812C10.7537 3.99812 9.57169 4.28612 8.51769 4.79812C8.81169 5.81412 8.55769 6.95612 7.75769 7.75612C6.95769 8.55812 5.81569 8.81012 4.79969 8.51612ZM16.9997 12.0001C16.9997 14.7581 14.7577 17.0001 11.9997 17.0001C11.3877 17.0001 10.7997 16.8881 10.2577 16.6861C8.31969 15.9961 6.92969 14.1441 6.92969 11.9741C6.92969 9.21612 9.17169 6.97412 11.9297 6.97412C12.5417 6.97412 13.1297 7.08612 13.6717 7.28812C15.6097 7.97812 16.9997 9.83012 16.9997 12.0001ZM11.9997 9.00012C10.3457 9.00012 8.99969 10.3461 8.99969 12.0001C8.99969 13.3201 9.85769 14.4421 11.0437 14.8421C11.3237 14.9281 11.6217 14.9761 11.9297 14.9761C13.5837 14.9761 14.9297 13.6301 14.9297 11.9761C14.9297 10.6561 14.0717 9.53412 12.8857 9.13412C12.6057 9.04812 12.3077 9.00012 11.9997 9.00012Z" />
      </svg>
      {{prelabel}}
    </label>
    <ul>
        <li v-for="site in siteList" :key="site.id">
            <a :href="site.url" :title="site.title" :target="site.target" :class="{ selected : site.id === siteSelected }">
                {{site.name}}
                <img v-if="site.logo" :src="site.logo" />
            </a>
        </li>
    </ul>
  </nav>
</template>

<script>

export default {
  name: 'SiteNavigation',
  props: {
    prelabel : {
        type: String,
        default: 'Vai al sito di:'
    },
    siteList : {
      type: Array,
      default: () => []
    },
    siteSelected : {
      type: Number,
      default: -1
    }
  }
}
</script>